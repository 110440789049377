.App {
  text-align: center;

}

.App-margin {
  margin: 125px 55px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.main-color {
  color: #6c5ce7
}
.main-backgroundcolor {
  background-color:#6c5ce7 ;
}

.about-background {
  background-color: #6c5ce7;
  height:42rem;
  margin-top: 10rem;
}

.about-cards {
  background-color: #7162e4;
  border-radius: 50px;
  box-shadow: -15px -15px 15px rgba(120, 81, 212, 0.651),
    13px 16px 25px rgba(120, 81, 212, 0.651);
}

.projects-header {
  border-radius: 20px;
  box-shadow: 0px -10px rgba(159, 129, 228, 0.651);

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-container {
  border-radius: 20px;


}

.project-container {
  background-color: rgba(7, 7, 7, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
  0 0  0 2px rgb(255,255,255),
  0.3em 0.3em 1em rgba(0,0,0,0.3);
  
  
}

.project-name {
  margin: 0;
  position: absolute;              
  top: 50%; 
  left: 50%;
  margin-right: -50%;                      
  transform: translate(-50%, -50%);
  padding: 1% 5%;
  border-radius: 3%;
  text-shadow:rgb(7, 7, 7) 6px 10px 10px;
}

.image-projects {
  border-radius: 20px;

}


.project-overview {
  position: absolute;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  overflow: auto;
  height: 100%;
  background-color: rgba(17, 13, 41);
  border-radius: 20px;
  border: solid 2px #6c5ce7;

}

@media only screen and (min-width: 1366px) {
  .project-flip:hover .project-overview {
    transform: translateY(0%);
  }
  .flip-arrow {
    display: none;
  }
  .mobile-display {
    display: none;
  }

  .card-size {
    width:30% !important
  }
}


.about-cards {
  background-color: #7162e4;
  border-radius: 50px;
  box-shadow: -15px -15px 15px rgba(120, 81, 212, 0.651),
    13px 16px 25px rgba(120, 81, 212, 0.651);
}

.project-button {
  background-color: #7162e4;
  border-radius: 10px;
  box-shadow: -1px -2px 2px rgba(120, 81, 212, 0.651),
    1px 2px 1px rgba(120, 81, 212, 0.651);
  color: rgb(255, 255, 255);
  text-decoration: none !important;
  color: inherit !important;
}

.contact-background {
  display: flex;
  min-height: 92vh;
  align-items: center;
  justify-content: center;

}

.contact-box{
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  
  border-radius: 3px;
  overflow: hidden;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #6c5ce7;
  color: #6c5ce7;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
}

.app-form-control::placeholder {
  color: rgba(120, 81, 212, 0.651);
  font-weight: bold;
}

@media (max-width: 520px) {  
  h3 {font-size:1.3rem;} /*1rem = 16px*/
  .rec-arrow-left {
    margin: 0px -23px 0px 0px !important;

  }
  .rec-arrow-right {
    margin: 0px 0px 0px -23px !important;

  }
  .mobile-padding {
    padding: 1px !important;

  }
  .contact-box{
    width: 80%;
  }

  .main-image {
    width:90% !important;
  }
  
}

@media (max-width: 991px) {  
  .about-background {
    background-color: #6c5ce7;
    height:72rem;
    margin-top: 10rem;
    
  }
  .about-cards{
  margin-bottom: 40px !important;
  width: 85% !important
    
  }

  .main-image {
    width:70%
  }
}

.project-width {
  width: 90%;
}

@media only screen and (max-width: 1366px) {
  .desktop-display {
    display: none !important;
  }
  .project-container {
    background-color: rgba(7, 7, 7, 0.6) !important; 
  }
}

.rounded-image {
  border-radius: 6rem;
}


@media only screen and (min-width: 500px) {
  .about-line {
    line-height: 2rem;
  }
 
}



